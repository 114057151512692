import HttpService from "../http/http.service";
import {
	ProviderActive,
	ProviderDetail,
	ProviderInfo,
	ProviderMinimalInfo,
	ProviderTwilio,
} from "../../models/entities/provider.model";
import { RiskUser } from "../../models/entities/risk-users.model";
import {
	Participant,
	ParticipantProfile,
	ParticipantState,
} from "../../models/entities/participant.model";
import {
	CallVoicemailActionRequest,
	CallVoicemailCountRequest,
	CallVoicemailRequest,
} from "../../models/endpoints/call-voicemail-endpoint.model";
import { CallerInfo } from "../../models/entities/call.model";
import {
	CreateParticipantRequest,
	ParticipantByIdRequest,
	ParticipantOverviewRequest,
	ParticipantWholeHealthRequest,
	ParticipantsCountRequest,
	ParticipantsRequest,
	UpdatePreferredCoachingMethodRequest,
} from "../../models/endpoints/participants-endpoint.model";
import {
	EngagementsRequest,
	CreateEngagementRequest,
	UpdateEngagementRequest,
} from "../../models/endpoints/engagements.model";
import { IEngagement } from "../../models/entities/engagement.model";
import { CallInfoRequest } from "../../models/endpoints/call-management-endpoint.model";
import {
	CallHistoryAdminRequest,
	CallHistoryCountRequest,
	CallHistoryRequest,
} from "../../models/endpoints/call-history-endpoint.model";
import { CallHistory } from "../../models/entities/call-history.model";
import { RecordingEntity } from "../../models/entities/call-voicemail.model";
import { Reminder } from "../../models/entities/reminder.model";
import {
	CreateReminderRequest,
	DeleteReminderRequest,
	RemindersCountRequest,
	RemindersRequest,
	UpdateReminderRequest,
} from "../../models/endpoints/reminders-endpoints.model";
import {
	BiometricsCreateRequest,
	BiometricsDeleteRequest,
	BiometricsEmailRequest,
	BiometricsPreviewRequest,
	BiometricsRequest,
	BiometricsUpdateRequest,
	GlucoseHistogramsRequest,
} from "../../models/endpoints/biometrics-endpoint.model";
import { Biometric } from "../../models/entities/biometrics.model";
import {
	GoalResponse,
	GoalsRequest,
	HedisGoalResponse,
} from "../../models/endpoints/goals_endpoint.model";
import {
	CreateOrUpdateMedicationCategoryNotesRequest,
	GetMedicationCategoryNotesRequest,
	MedicationLatestResponse,
	MedicationsCreateRequest,
	MedicationsDeleteRequest,
	MedicationsListRequest,
	MedicationsRequest,
	MedicationsUpdateRequest,
} from "../../models/endpoints/medications-endpoint.model";
import {
	CreateServiceUtilizationEntryRequest,
	ServiceUtilizationGoalsRequest,
	ServiceUtilizationGoalsResponse,
} from "../../models/endpoints/service-utilization-goals-endpoint.model";
import {
	CreateParticipantNoteRequest,
	EditParticipantNoteRequest,
	ParticipantNotesRequest,
} from "../../models/notes/note-endpoints";
import {
	CreateConditionsRequest,
	GetConditionsRequest,
	UpdateConditionRequest,
} from "../../models/endpoints/conditions.model";
import { Condition } from "../../models/entities/condition.model";
import {
	SendEventRequest,
	UpdateProfileContactRequest,
	UpdateProfileGeneralInfoRequest,
	UpdateProfileKannactInfoRequest,
	UpdateProfileSubscriptionsRequest,
} from "../../models/endpoints/participant-profile.model";
import { Institution } from "../../models/entities/institution.model";
import { AppointmentsBlockInfoRequest } from "../../models/endpoints/appointments-endpoint.model";
import { AppointmentBlockInfo } from "../../models/entities/appointments.model";
import {
	CreateProviderRequest,
	GetInstitutionDetailsRequest,
	GetProvidersActiveRequest,
	GetProvidersCountRequest,
	GetProvidersInfoRequest,
	GetProvidersRequest,
	UpdateProviderRequest,
} from "../../models/endpoints/providers-endpoint.model";
import {
	Procedure,
	ProcedureCreateRequest,
	ProcedureGetRequest,
} from "../../models/procedures.model";
import {
	CustomGoalsDeleteRequest,
	CustomGoalsGetRequest,
	LongTermGoalsCompleteRequest,
	LongTermGoalsCreateRequest,
	LongTermGoalsUpdateRequest,
	SmartGoalsCompleteRequest,
	SmartGoalsCreateRequest,
	SmartGoalsUpdateRequest,
} from "../../models/custom-goals/custom-goals-endpoints";
import { LongTermGoal, SmartGoal } from "../../models/custom-goals/custom-goals";
import { Note } from "../../models/notes/note";
import {
	ParticipantsAlertsCountRequest,
	ParticipantsAlertsRequest,
	ParticipantsAlertsUpdateRequest,
} from "../../models/endpoints/alerts.model";
import { Alert } from "../../models/entities/alert.model";
import {
	PhysiciansGetRequest,
	PhysiciansUpdateRequest,
} from "../../models/endpoints/physicians-endpoint.model";
import { Physician } from "../../models/entities/physician.model";

const httpService = new HttpService(process.env.REACT_APP_API_DATA_RETRIEVER!);

// Data retriver endpoints
export const DataRetrieverEndpointsService = {
	getCallInfo: async (request: CallInfoRequest): Promise<CallerInfo[]> =>
		httpService.get("/api/patients/callInfo", request),

	/* Institutions */

	getInstitutions: async (): Promise<string[]> => httpService.get("/api/institutions/"),
	getInstitutionsDetailed: async (): Promise<Institution[]> =>
		httpService.get("/api/institutions/detailed"),
	getInstitutionDetails: async (request: GetInstitutionDetailsRequest): Promise<Institution> =>
		httpService.get(`/api/institutions/${request.institutionId}`),
	/* Providers */

	getProvidersInfo: async (request?: GetProvidersInfoRequest): Promise<ProviderInfo[]> =>
		httpService.get("/api/providers/info", request),
	getProvidersMinimalInfo: async (): Promise<ProviderMinimalInfo[]> =>
		httpService.get("/api/providers/minimal"),
	getProviderLogged: async (): Promise<ProviderInfo> =>
		httpService.get("/api/providers/detailed"),
	getProvidersTwilio: async (): Promise<ProviderTwilio[]> =>
		httpService.get("/api/providers/twilio"),
	getProviders: async (request: GetProvidersRequest): Promise<ProviderDetail[]> =>
		httpService.get("/api/providers/", request),
	getProvidersActive: async (request: GetProvidersActiveRequest): Promise<ProviderActive[]> =>
		httpService.get(`/api/providers/active`, request),
	getProvidersCount: async (request: GetProvidersCountRequest): Promise<number> =>
		httpService.get("/api/providers/count", request),
	createProvider: async (request: CreateProviderRequest): Promise<ProviderDetail> =>
		httpService.post(`/api/providers/`, request),
	updateProvider: async (request: UpdateProviderRequest): Promise<ProviderDetail> =>
		httpService.patch(`/api/providers/`, request),

	/* Risk users */

	getRiskUsers: async (): Promise<RiskUser[]> => httpService.get("/api/riskusers/all"),
	getRiskUsersByCoach: async (): Promise<RiskUser[]> => httpService.get("/api/riskusers/"),

	/* Participants */

	getParticipants: async (request: ParticipantsRequest): Promise<Participant[]> =>
		httpService.get("/api/patients/", request),
	getParticipantsCount: async (request: ParticipantsCountRequest): Promise<number> =>
		httpService.get("/api/patients/count", request),
	getParticipantById: async (request: ParticipantByIdRequest): Promise<ParticipantProfile> =>
		httpService.get(`/api/patients/${request.participantId}`),
	createParticipant: async (request: CreateParticipantRequest): Promise<ParticipantProfile> =>
		httpService.post(`/api/patients/`, request),
	updatePreferredCoachingMethod: async (
		request: UpdatePreferredCoachingMethodRequest
	): Promise<void> =>
		httpService.put(
			`/api/patients/${request.kannactId}/preferredCoachingMethod?preferredCoachingMethod=${request.preferredCoachingMethod}`,
			{}
		),
	updateOverview: async (request: ParticipantOverviewRequest): Promise<void> =>
		httpService.put(`/api/patients/${request.participantId}/overview`, request),
	updateWholeHealth: async (request: ParticipantWholeHealthRequest): Promise<void> =>
		httpService.put(`/api/patients/${request.participantId}/isWholeHealth`, request),
	getRoStatus: async (): Promise<string[]> => httpService.get(`/api/patients/ro-status`),
	getParticipantMinimal: async (
		request: AppointmentsBlockInfoRequest
	): Promise<AppointmentBlockInfo[]> => httpService.get(`/api/patients/minimal`, request),

	/* Engagements */
	getEngagements: async (request: EngagementsRequest): Promise<IEngagement[]> =>
		httpService.get(`/api/engagements/`, request),
	createEngagement: async (request: CreateEngagementRequest): Promise<IEngagement> =>
		httpService.post("/api/engagements/", request),
	updateEngagement: async (request: UpdateEngagementRequest): Promise<IEngagement> =>
		httpService.put("/api/engagements/", request),

	/* Calls history */
	getHistory: async (request: CallHistoryRequest): Promise<CallHistory[]> =>
		httpService.get("/api/calls/", request),
	getHistoryAdmin: async (request: CallHistoryAdminRequest): Promise<CallHistory[]> =>
		httpService.get("/api/calls/", request),
	getHistoryCount: async (request: CallHistoryCountRequest): Promise<number> =>
		httpService.get("/api/calls/count", request),

	/* Voicemails */

	getVoicemails: async (request: CallVoicemailRequest): Promise<RecordingEntity[]> =>
		httpService.get("/api/voicemails/", request),
	getVoicemailsCount: async (request: CallVoicemailCountRequest): Promise<number> =>
		httpService.get("/api/voicemails/count", request),
	voicemailResolve: async (request: CallVoicemailActionRequest): Promise<void> =>
		httpService.post("/api/voicemails/", request),

	/* Reminders */

	getReminders: async (request: RemindersRequest): Promise<Reminder[]> =>
		httpService.get("/api/reminders/", request),
	getRemindersCount: async (request: RemindersCountRequest): Promise<number> =>
		httpService.get("/api/reminders/count", request),
	createReminder: async (request: CreateReminderRequest): Promise<Reminder> =>
		httpService.post("/api/reminders/", request),
	updateReminder: async (request: UpdateReminderRequest): Promise<Reminder> =>
		httpService.put("/api/reminders/", request),
	deleteReminder: async (request: DeleteReminderRequest): Promise<void> =>
		httpService.delete(`/api/reminders/${request.id}`),

	/* Biometrics */

	getAllBiometrics: async (request: BiometricsRequest): Promise<Biometric[]> =>
		httpService.get(`/api/biometrics/`, request),
	createBiometric: async (request: BiometricsCreateRequest): Promise<Biometric> =>
		httpService.post("/api/biometrics/", request),
	updateBiometric: async (request: BiometricsUpdateRequest): Promise<Biometric> =>
		httpService.patch(`/api/biometrics/${request.id}`, request),
	deleteBiometrics: async (request: BiometricsDeleteRequest): Promise<boolean> =>
		httpService.put(`/api/biometrics/delete`, request),
	createBiometricPreview: async (request: BiometricsPreviewRequest): Promise<Blob> =>
		httpService.post(`/api/biometrics/${request.participantId}/report`, request),
	createBiometricEmailReport: async (request: BiometricsEmailRequest): Promise<boolean> =>
		httpService.post(`/api/biometrics/${request.participantId}/report/email`, request),
	getGlucoseHistograms: async (request: GlucoseHistogramsRequest): Promise<Blob> =>
		httpService.get(`/api/biometrics/histograms`, request),

	/* Goals */

	getBiometricGoals: async (request: GoalsRequest): Promise<GoalResponse | null> =>
		httpService.get(`/api/goals/biometric/${request.participantId}`, request),
	getMedicationGoals: async (request: GoalsRequest): Promise<GoalResponse | null> =>
		httpService.get(`/api/goals/medication/${request.participantId}`, request),
	getHedisGoals: async (request: GoalsRequest): Promise<HedisGoalResponse[]> =>
		httpService.get(`/api/patients/${request.participantId}/hedis-goals`, request),

	/* Medications */

	getMedicationsLatest: async (
		request: MedicationsRequest
	): Promise<MedicationLatestResponse[]> => httpService.get(`/api/medications/latest`, request),
	getMedicationsList: async (request: MedicationsListRequest): Promise<string[]> =>
		httpService.get(`/api/medications/find`, request),
	createMedication: async (
		request: MedicationsCreateRequest
	): Promise<MedicationLatestResponse> => httpService.post(`/api/medications/`, request),
	updateMedication: async (
		request: MedicationsUpdateRequest
	): Promise<MedicationLatestResponse> => httpService.put(`/api/medications/`, request),
	deleteMedication: async (request: MedicationsDeleteRequest): Promise<boolean> =>
		httpService.delete(`/api/medications/${request.id}`),
	getMedicationCategoryNotes: (request: GetMedicationCategoryNotesRequest): Promise<string> =>
		httpService.get(
			`/api/medications/${request.participantId}/notes/${request.category}`,
			request
		),
	createOrUpdateMedicationCategoryNotes: (
		request: CreateOrUpdateMedicationCategoryNotesRequest
	): Promise<string> =>
		httpService.post(
			`/api/medications/${request.participantId}/notes/${request.category}`,
			request
		),

	/* Service Utilization Goals */

	getServiceUtilizationGoals: async (
		request: ServiceUtilizationGoalsRequest
	): Promise<ServiceUtilizationGoalsResponse> =>
		httpService.get(`/api/goals/${request.participantId}`),

	createServiceUtilizationEntry: async (
		request: CreateServiceUtilizationEntryRequest
	): Promise<MedicationLatestResponse> =>
		httpService.post(`/api/goals/${request.participantId}`, request),

	/* Participant notes */

	getParticipantNotes: async (request: ParticipantNotesRequest): Promise<Note[]> =>
		httpService.get(`/api/participant-notes/`, request),

	createParticipantNotes: async (request: CreateParticipantNoteRequest): Promise<void> =>
		httpService.post(`/api/participant-notes/`, request),

	editParticipantNote: async (request: EditParticipantNoteRequest): Promise<void> =>
		httpService.put(`/api/participant-notes/`, request),

	/* Participant: Conditions */

	getConditions: async (request: GetConditionsRequest): Promise<Condition[]> =>
		httpService.get(`/api/conditions/`, request),
	getConditionsOptions: async (): Promise<string[]> => httpService.get(`/api/conditions/options`),
	createConditions: async (request: CreateConditionsRequest): Promise<void> =>
		httpService.post(`/api/conditions/`, request),
	updateCondition: async (request: UpdateConditionRequest): Promise<Condition> =>
		httpService.patch(`/api/conditions/${request.participantConditionId}`, request),

	/* Participant: Profile */

	getPronouns: async (): Promise<string[]> => httpService.get(`/api/participantProfile/pronouns`),
	getLanguages: async (): Promise<string[]> =>
		httpService.get(`/api/participantProfile/languages`),
	getStatuses: async (): Promise<string[]> => httpService.get(`/api/participantProfile/statuses`),
	getUnenrollmentReasons: async (): Promise<string[]> =>
		httpService.get(`/api/participantProfile/unenrollmentReasons`),
	getFrequencies: async (): Promise<string[]> =>
		httpService.get(`/api/participantProfile/frequencies`),
	getAddressStates: async (): Promise<ParticipantState[]> => httpService.get(`/public/states`),
	updateProfileKannactInfo: async (
		request: UpdateProfileKannactInfoRequest
	): Promise<ParticipantProfile> =>
		httpService.patch(`/api/participantProfile/kannactInformation`, request),
	updateProfileGeneralInfo: async (
		request: UpdateProfileGeneralInfoRequest
	): Promise<ParticipantProfile> =>
		httpService.patch(`/api/participantProfile/generalInformation`, request),
	updateProfileSubscriptions: async (
		request: UpdateProfileSubscriptionsRequest
	): Promise<ParticipantProfile> =>
		httpService.patch(`/api/participantProfile/subscriptionInformation`, request),
	updateProfileContactInfo: async (
		request: UpdateProfileContactRequest
	): Promise<ParticipantProfile> =>
		httpService.patch(`/api/participantProfile/contactInformation`, request),
	participantOptIn: async (request: SendEventRequest): Promise<void> =>
		httpService.post(`/api/participantProfile/opt-in`, request),

	/* Procedures */

	getProcedures: async (request: ProcedureGetRequest): Promise<Procedure[]> =>
		httpService.get(`/api/procedures/`, request),

	createProcedure: async (request: ProcedureCreateRequest): Promise<Procedure> =>
		httpService.post(`/api/procedures/`, request),

	/* Smart goals */

	getSmartGoals: async (request: CustomGoalsGetRequest): Promise<SmartGoal[]> =>
		httpService.get(`/api/smart-goals/`, request),
	createSmartGoal: async (request: SmartGoalsCreateRequest): Promise<SmartGoal> =>
		httpService.post(`/api/smart-goals/`, request),
	updateSmartGoal: async (request: SmartGoalsUpdateRequest): Promise<SmartGoal> =>
		httpService.put(`/api/smart-goals/`, request),
	deleteSmartGoal: async (request: CustomGoalsDeleteRequest): Promise<SmartGoal> =>
		httpService.patch(`/api/smart-goals/delete`, request),
	completeSmartGoal: async (request: SmartGoalsCompleteRequest): Promise<SmartGoal> =>
		httpService.patch(`/api/smart-goals/complete`, request),

	/* Long term goals */

	getLongTermGoals: async (request: CustomGoalsGetRequest): Promise<LongTermGoal[]> =>
		httpService.get(`/api/long-term-goals/`, request),
	createLongTermGoal: async (request: LongTermGoalsCreateRequest): Promise<LongTermGoal> =>
		httpService.post(`/api/long-term-goals/`, request),
	updateLongTermGoal: async (request: LongTermGoalsUpdateRequest): Promise<LongTermGoal> =>
		httpService.put(`/api/long-term-goals/`, request),
	deleteLongTermGoal: async (request: CustomGoalsDeleteRequest): Promise<LongTermGoal> =>
		httpService.patch(`/api/long-term-goals/delete`, request),
	completeLongTermGoal: async (request: LongTermGoalsCompleteRequest): Promise<LongTermGoal> =>
		httpService.patch(`/api/long-term-goals/complete`, request),

	/* Alerts */

	getAlerts: async (request: ParticipantsAlertsRequest): Promise<Alert[]> =>
		httpService.get(`/api/alerts/`, request),
	getAlertsCount: async (request: ParticipantsAlertsCountRequest): Promise<number> =>
		httpService.get(`/api/alerts/count`, request),
	updateAlert: async (request: ParticipantsAlertsUpdateRequest): Promise<Alert> =>
		httpService.patch(`/api/alerts/${request.body.id}`, request),

	/* Physicians */

	getPhysicians: async (request: PhysiciansGetRequest): Promise<Physician[]> =>
		httpService.get(`/api/physicians/${request.participantId}`),
	updatePhysicians: async (request: PhysiciansUpdateRequest): Promise<Physician[]> =>
		httpService.patch(`/api/physicians/${request.participantId}`, request),
};
