import { useState } from "react";
import { useTranslation } from "react-i18next";
import ParticipantDetailsCard from "./ParticipantDetailsCard";
import {
	DaysOfWeek,
	TimeOfDay,
	Timezone,
	ParticipantProfile,
} from "../../../models/entities/participant.model";
import { Form } from "react-final-form";
import ParticipantDetailsField from "./ParticipantDetailsField";
import { FormApi } from "final-form";
import { Formatter } from "../../../services/formatter/formatter.service";
import { Validations } from "../../../services/form/validations.service";
import UiInputText from "../../../components/input-text/InputText.component";
import UiSelect from "../../../components/select/Select.component";
import { List, ListOption } from "../../../models/misc.model";
import UiInputCheckbox from "../../../components/input-checkbox/InputCheckbox.component";
import { useAddressStates } from "../../../hooks/useAddressStates";
import UiInputNumber from "../../../components/input-number/InputNumber.component";
import { Phone } from "../../../models/entities/phone.model";
import { ContactBody } from "../../../models/endpoints/participant-profile.model";
import { ParticipantDetailsContactService } from "../../../services/participant-details/participant-details-contact.service";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import { PtContactForm } from "../../../models/pages/participant-details/contact-info.model";
import { PtDetailsMode } from "../../../models/pages/participant-details/participant-details.model";
import { Roles } from "../../../models/roles.model";

const ParticipantDetailsContact = ({
	participant,
	addressStatesQuery,
	onUpdate,
}: {
	participant: ParticipantProfile;
	addressStatesQuery: ReturnType<typeof useAddressStates>;
	onUpdate: (updatedParticipant: ParticipantProfile) => void;
}) => {
	const { t } = useTranslation("common");

	const [mode, setMode] = useState<PtDetailsMode>(PtDetailsMode.READ);
	const [showError, setShowError] = useState<boolean>(false);
	const [formData, setFormData] = useState<PtContactForm>(new PtContactForm(participant));

	const setEmailList = (email1: string | null, email2: string | null) => {
		const result: List = [];
		if (email1) result.push({ id: email1, label: email1 });
		if (email2) result.push({ id: email2, label: email2 });
		return result;
	};

	const setPhoneList = (phone1: number | null, phone2: number | null, phone3: number | null) => {
		const result: List = [];
		if (phone1) result.push({ id: phone1, label: phone1 });
		if (phone2) result.push({ id: phone2, label: phone2 });
		if (phone3) result.push({ id: phone3, label: phone3 });
		return result;
	};

	const setPhone = (phone: Phone) =>
		Formatter.phoneNumber(`${phone.countryCode}${phone.phoneNumber}`);

	const setTimeOfDay = (timeOfDay: string | null) => {
		let result = timeOfDay;
		const mapped = ["8AM_10AM", "10AM_NOON", "NOON_2PM", "2PM_4PM", "4PM_6PM", "6PM_8PM"];

		if (timeOfDay && mapped.indexOf(timeOfDay) !== -1) {
			result = t(`ENUMS.TIME_OF_DAY.${timeOfDay}`);
		}

		return result;
	};

	const setDaysOfWeek = (daysOfWeek: string | null) => {
		const mapped = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];
		const result = daysOfWeek
			? daysOfWeek
					.split(",")
					.map((item) => {
						if (mapped.indexOf(item) !== -1) {
							item = t(`ENUMS.DAYS_OF_WEEK.${item}`);
						}
						return item;
					})
					.join(", ")
			: null;
		return result;
	};

	const setRequest = () => {
		const result: Partial<ContactBody> = {
			...ParticipantDetailsContactService.setRequestPhones(participant, formData),
			...ParticipantDetailsContactService.setRequestEmails(participant, formData),
			...ParticipantDetailsContactService.setRequestScheduling(participant, formData),
			...ParticipantDetailsContactService.setRequestShipmentAddress(participant, formData),
			...ParticipantDetailsContactService.setRequestPreferredCoachingMethod(
				participant,
				formData
			),
		};

		return result;
	};

	const handleSubmit = async (
		data: PtContactForm,
		form: FormApi<PtContactForm, PtContactForm>
	) => {
		setShowError(false);

		const body = setRequest();

		await EndpointsService.dataRetriever
			.updateProfileContactInfo({
				body: {
					participantId: participant.id,
					...body,
				},
			})
			.then((response) => {
				// Update form state
				form.reset();

				// Update participant state
				onUpdate(response);

				// Update mode
				setMode(PtDetailsMode.READ);
			})
			.catch((error) => setShowError(true));
	};

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={formData}
			render={({ handleSubmit, form, submitting }) => (
				<form onSubmit={handleSubmit}>
					<ParticipantDetailsCard
						title="PARTICIPANT.DETAILS.CONTACT.TITLE"
						submitting={submitting}
						mode={mode}
						enableEditing={!Roles.isProvider()}
						showError={showError}
						enableSubmit={!!Object.keys(setRequest())}
						onCancel={() => {
							form.reset();
							setShowError(false);
							setFormData(new PtContactForm(participant));
							setMode(PtDetailsMode.READ);
						}}
						onModeChange={() => setMode(PtDetailsMode.UPDATE)}
					>
						{/* Phone numbers */}
						<div className="row">
							<div className="col-12 fields-section-title">
								{t("PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.phoneNumbers")}
							</div>

							{/* Phone 1 (primary) */}
							<div className="col-12 col-md-8 col-lg-8 col-xl-4">
								{mode === PtDetailsMode.READ ? (
									<ParticipantDetailsField
										title={
											participant.phones?.length &&
											Number(participant.phones[0].isPrimary)
												? `${t(
														"PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.phone1"
													)} ${t(
														"PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.primary"
													)}`
												: "PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.phone1"
										}
										value={
											participant.phones?.length
												? setPhone(participant.phones[0])
												: null
										}
									/>
								) : (
									<UiInputNumber
										id="phone1"
										label="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.phone1"
										name="phone1"
										prefix="+1 "
										useGrouping={false}
										onChange={(e) => {
											if (formData.primaryPhone === formData.phone1) {
												setFormData({
													...formData,
													primaryPhone: e.value,
													phone1: e.value,
												});
											} else {
												setFormData({ ...formData, phone1: e.value });
											}
										}}
										validations={formData.phone1 ? [Validations.numbers] : []}
										disabled={submitting}
									/>
								)}
							</div>

							{/* Phone 2 */}
							<div className="col-12 col-md-8 col-lg-8 col-xl-4">
								{mode === PtDetailsMode.READ ? (
									<ParticipantDetailsField
										title={
											participant.phones?.length > 1 &&
											Number(participant.phones[1].isPrimary)
												? `${t(
														"PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.phone2"
													)} ${t(
														"PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.primary"
													)}`
												: "PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.phone2"
										}
										value={
											participant.phones?.length > 1
												? setPhone(participant.phones[1])
												: null
										}
									/>
								) : (
									<UiInputNumber
										id="phone2"
										label="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.phone2"
										name="phone2"
										prefix="+1 "
										useGrouping={false}
										onChange={(e) => {
											if (formData.primaryPhone === formData.phone2) {
												setFormData({
													...formData,
													primaryPhone: e.value,
													phone2: e.value,
												});
											} else {
												setFormData({ ...formData, phone2: e.value });
											}
										}}
										validations={formData.phone2 ? [Validations.numbers] : []}
										disabled={submitting}
									/>
								)}
							</div>

							{/* Phone 3 */}
							<div className="col-12 col-md-8 col-lg-8 col-xl-4">
								{mode === PtDetailsMode.READ ? (
									<ParticipantDetailsField
										title={
											participant.phones?.length > 2 &&
											Number(participant.phones[2].isPrimary)
												? `${t(
														"PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.phone3"
													)} ${t(
														"PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.primary"
													)}`
												: "PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.phone3"
										}
										value={
											participant.phones?.length > 2
												? setPhone(participant.phones[2])
												: null
										}
									/>
								) : (
									<UiInputNumber
										id="phone3"
										label="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.phone3"
										name="phone3"
										prefix="+1 "
										useGrouping={false}
										onChange={(e) => {
											if (formData.primaryPhone === formData.phone3) {
												setFormData({
													...formData,
													primaryPhone: e.value,
													phone3: e.value,
												});
											} else {
												setFormData({ ...formData, phone3: e.value });
											}
										}}
										validations={formData.phone3 ? [Validations.numbers] : []}
										disabled={submitting}
									/>
								)}
							</div>

							{/* Primary phone */}
							{mode === PtDetailsMode.UPDATE && (
								<div className="col-12 col-md-8 col-lg-8 col-xl-4">
									<UiSelect
										id="primaryPhone"
										label="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.primaryPhone"
										name="primaryPhone"
										onChange={(e) =>
											setFormData({
												...formData,
												primaryPhone: e.target.value,
											})
										}
										disabled={submitting}
										options={setPhoneList(
											formData.phone1,
											formData.phone2,
											formData.phone3
										)}
										validations={
											formData.phone1 || formData.phone2 || formData.phone3
												? [Validations.required]
												: []
										}
									/>
								</div>
							)}
						</div>

						{/* Emails */}
						<div className="row">
							<div className="col-12 fields-section-title">
								{t("PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.emails")}
							</div>

							{/* Email 1 (primary) */}
							<div className="col-12 col-xl-4">
								{mode === PtDetailsMode.READ ? (
									<ParticipantDetailsField
										title={
											participant.emails?.length &&
											participant.emails[0].emailAddress ===
												formData.primaryEmail
												? `${t(
														"PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.email1"
													)} ${t(
														"PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.primary"
													)}`
												: "PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.email1"
										}
										value={
											participant.emails?.length
												? participant.emails[0].emailAddress
												: null
										}
									/>
								) : (
									<UiInputText
										id="email1"
										label="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.email1"
										name="email1"
										onChange={(e) => {
											if (formData.primaryEmail === formData.email1) {
												setFormData({
													...formData,
													primaryEmail: e.target.value,
													email1: e.target.value,
												});
											} else {
												setFormData({
													...formData,
													email1: e.target.value,
												});
											}
										}}
										validations={formData.email1 ? [Validations.email] : []}
										disabled={submitting}
									/>
								)}
							</div>

							{/* Email 2 */}
							<div className="col-12 col-xl-4">
								{mode === PtDetailsMode.READ ? (
									<ParticipantDetailsField
										title={
											participant.emails?.length > 1 &&
											participant.emails[1].emailAddress ===
												formData.primaryEmail
												? `${t(
														"PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.email2"
													)} ${t(
														"PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.primary"
													)}`
												: "PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.email2"
										}
										value={
											participant.emails?.length > 1
												? participant.emails[1].emailAddress
												: null
										}
									/>
								) : (
									<UiInputText
										id="email2"
										label="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.email2"
										name="email2"
										onChange={(e) => {
											if (formData.primaryEmail === formData.email2) {
												setFormData({
													...formData,
													primaryEmail: e.target.value,
													email2: e.target.value,
												});
											} else {
												setFormData({
													...formData,
													email2: e.target.value,
												});
											}
										}}
										validations={formData.email2 ? [Validations.email] : []}
										disabled={submitting}
									/>
								)}
							</div>

							{/* Primary email */}
							{mode === PtDetailsMode.UPDATE && (
								<div className="col-12 col-xl-4">
									<UiSelect
										id="primaryEmail"
										label="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.primaryEmail"
										name="primaryEmail"
										onChange={(e) =>
											setFormData({
												...formData,
												primaryEmail: e.target.value,
											})
										}
										disabled={submitting}
										options={setEmailList(formData.email1, formData.email2)}
										validations={
											formData.email1 || formData.email2
												? [Validations.required]
												: []
										}
									/>
								</div>
							)}
						</div>

						{/* Coaching by */}
						<div className="row">
							<div className="col-12 col-md-6 col-lg-6 col-xl-4">
								{mode === PtDetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.preferredCoachingMethod"
										value={
											participant.preferredCoachingMethod
												? t(
														Formatter.preferredCoachingMethod(
															participant.preferredCoachingMethod
														)
													)
												: null
										}
									/>
								) : (
									<UiSelect
										id="preferredCoachingMethod"
										placeholder="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.preferredCoachingMethod"
										label="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.preferredCoachingMethod"
										name="preferredCoachingMethod"
										onChange={(e) =>
											setFormData({
												...formData,
												preferredCoachingMethod: e.target.value,
											})
										}
										options={[
											{
												id: "PHONE",
												label: Formatter.preferredCoachingMethod("PHONE"),
											},
											{
												id: "CARE_MESSAGE",
												label: Formatter.preferredCoachingMethod(
													"CARE_MESSAGE"
												),
											},
											{
												id: "EMAIL",
												label: Formatter.preferredCoachingMethod("EMAIL"),
											},
										]}
										disabled={submitting}
									/>
								)}
							</div>
						</div>

						{/* Scheduling */}
						<div className="row">
							<div className="col-12 fields-section-title">
								{t("PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.scheduling")}
							</div>

							{/* Timezone */}
							<div className="col-12 col-md-6 col-lg-6 col-xl-4">
								{mode === PtDetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.timezone"
										value={participant.timezone}
									/>
								) : (
									<UiSelect
										id="timezone"
										placeholder="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.timezone"
										label="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.timezone"
										name="timezone"
										onChange={(e) =>
											setFormData({ ...formData, timezone: e.target.value })
										}
										options={[
											{
												id: Timezone.US_Eastern,
												label: `${Timezone.US_Eastern}`,
											},
											{
												id: Timezone.US_Central,
												label: `${Timezone.US_Central}`,
											},
											{
												id: Timezone.US_Mountain,
												label: `${Timezone.US_Mountain}`,
											},
											{
												id: Timezone.US_Pacific,
												label: `${Timezone.US_Pacific}`,
											},
											{
												id: Timezone.US_Alaska,
												label: `${Timezone.US_Alaska}`,
											},
											{
												id: Timezone.US_Hawaii,
												label: `${Timezone.US_Hawaii}`,
											},
											{
												id: Timezone.US_Arizona,
												label: `${Timezone.US_Arizona}`,
											},
											{ id: Timezone.NA, label: `${Timezone.NA}` },
										]}
										disabled={submitting}
									/>
								)}
							</div>

							{/* Time of day preference */}
							<div className="col-12 col-md-6 col-lg-6 col-xl-4">
								{mode === PtDetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.timeOfDay"
										value={setTimeOfDay(participant.timeOfDay)}
									/>
								) : (
									<UiSelect
										id="timeOfDay"
										placeholder="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.timeOfDay"
										label="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.timeOfDay"
										name="timeOfDay"
										onChange={(e) =>
											setFormData({ ...formData, timeOfDay: e.target.value })
										}
										options={[
											{
												id: TimeOfDay["8AM_10AM"],
												label: `ENUMS.TIME_OF_DAY.${TimeOfDay["8AM_10AM"]}`,
											},
											{
												id: TimeOfDay["10AM_NOON"],
												label: `ENUMS.TIME_OF_DAY.${TimeOfDay["10AM_NOON"]}`,
											},
											{
												id: TimeOfDay.NOON_2PM,
												label: `ENUMS.TIME_OF_DAY.${TimeOfDay.NOON_2PM}`,
											},
											{
												id: TimeOfDay["2PM_4PM"],
												label: `ENUMS.TIME_OF_DAY.${TimeOfDay["2PM_4PM"]}`,
											},
											{
												id: TimeOfDay["4PM_6PM"],
												label: `ENUMS.TIME_OF_DAY.${TimeOfDay["4PM_6PM"]}`,
											},
											{
												id: TimeOfDay["6PM_8PM"],
												label: `ENUMS.TIME_OF_DAY.${TimeOfDay["6PM_8PM"]}`,
											},
										]}
										disabled={submitting}
									/>
								)}
							</div>

							{/* Preferred days of the week */}
							<div className="col-12">
								{mode === PtDetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.daysOfWeek"
										value={setDaysOfWeek(participant.daysOfWeek)}
									/>
								) : (
									<div className="row">
										<div className="col-12 pt-details-field">
											<div className="field-title">
												{t(
													"PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.daysOfWeek"
												)}
											</div>
											<div>
												{t(
													"PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.daysOfWeekInfo"
												)}
											</div>
										</div>

										<div className="col-6">
											<UiInputCheckbox
												id="daysOfWeek"
												name="daysOfWeek"
												onChange={(e) =>
													setFormData({
														...formData,
														daysOfWeek: e.target.value,
													})
												}
												disabled={submitting}
												options={[
													{
														id: DaysOfWeek.MO,
														label: `ENUMS.DAYS_OF_WEEK.${DaysOfWeek.MO}`,
													},
													{
														id: DaysOfWeek.TU,
														label: `ENUMS.DAYS_OF_WEEK.${DaysOfWeek.TU}`,
													},
													{
														id: DaysOfWeek.WE,
														label: `ENUMS.DAYS_OF_WEEK.${DaysOfWeek.WE}`,
													},
													{
														id: DaysOfWeek.TH,
														label: `ENUMS.DAYS_OF_WEEK.${DaysOfWeek.TH}`,
													},
													{
														id: DaysOfWeek.FR,
														label: `ENUMS.DAYS_OF_WEEK.${DaysOfWeek.FR}`,
													},
												]}
											/>
										</div>
										<div className="col-6">
											<UiInputCheckbox
												id="daysOfWeek"
												name="daysOfWeek"
												onChange={(e) =>
													setFormData({
														...formData,
														daysOfWeek: e.target.value,
													})
												}
												disabled={submitting}
												options={[
													{
														id: DaysOfWeek.SA,
														label: `ENUMS.DAYS_OF_WEEK.${DaysOfWeek.SA}`,
													},
													{
														id: DaysOfWeek.SU,
														label: `ENUMS.DAYS_OF_WEEK.${DaysOfWeek.SU}`,
													},
												]}
											/>
										</div>
									</div>
								)}
							</div>
						</div>

						{/* Address */}
						<div className="">
							<div className="fields-section-title">
								{t("PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.address")}
							</div>

							{/* Shipping address */}
							<div className="">
								{mode === PtDetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.shippingAddress"
										value={[
											participant.shippingAddressAddressLine1,
											participant.shippingAddressAddressLine2,
											participant.shippingAddressCity,
											// participant.shippingAddressCountry,
											participant.shippingAddressState,
											participant.shippingAddressPostalCode,
										]
											.filter((x) => x != null && x.length > 0)
											.join(", ")}
									/>
								) : (
									<div className="row">
										<div className="col-12 col-lg-6">
											<UiInputText
												id="shippingAddressAddressLine1"
												label="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.shippingAddressAddressLine1"
												name="shippingAddressAddressLine1"
												onChange={(e) =>
													setFormData({
														...formData,
														shippingAddressAddressLine1: e.target.value,
													})
												}
												disabled={submitting}
											/>
										</div>
										<div className="col-12 col-lg-6">
											<UiInputText
												id="shippingAddressAddressLine2"
												label="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.shippingAddressAddressLine2"
												name="shippingAddressAddressLine2"
												onChange={(e) =>
													setFormData({
														...formData,
														shippingAddressAddressLine2: e.target.value,
													})
												}
												disabled={submitting}
											/>
										</div>
										<div className="col-12 col-lg-4">
											<UiInputText
												id="shippingAddressCity"
												label="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.shippingAddressCity"
												name="shippingAddressCity"
												onChange={(e) =>
													setFormData({
														...formData,
														shippingAddressCity: e.target.value,
													})
												}
												disabled={submitting}
											/>
										</div>
										<div className="col-12 col-lg-4">
											<UiSelect
												id="shippingAddressState"
												placeholder="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.shippingAddressState"
												label="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.shippingAddressState"
												name="shippingAddressState"
												onChange={(e) =>
													setFormData({
														...formData,
														shippingAddressState: e.target.value,
													})
												}
												filter={true}
												options={
													addressStatesQuery.data
														? addressStatesQuery.data?.map(
																(entry) =>
																	new ListOption({
																		id: entry.abbrevation,
																		label: entry.name,
																	})
															)
														: []
												}
												disabled={
													submitting || addressStatesQuery.isLoading
												}
											/>
										</div>
										<div className="col-12 col-lg-4">
											<UiInputText
												id="shippingAddressPostalCode"
												label="PARTICIPANT.DETAILS.CONTACT.FORM_FIELDS.shippingAddressPostalCode"
												name="shippingAddressPostalCode"
												onChange={(e) =>
													setFormData({
														...formData,
														shippingAddressPostalCode: e.target.value,
													})
												}
												disabled={submitting}
											/>
										</div>
									</div>
								)}
							</div>
						</div>
					</ParticipantDetailsCard>
				</form>
			)}
		/>
	);
};

export default ParticipantDetailsContact;
